@font-face {
  font-family: poppins;
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: novassist;
  src: url(../../assets/fonts/ag-book-rounded-regular-30669.ttf);
}

body {
  font-family: poppins;
}

.novassist-brand {
  font-family: novassist;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 2rem;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background: rgba(0, 0, 0, 0.5);
}

.ant-btn-primary {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
  border-radius: 5rem;
  min-width: 8rem;
}

.ant-btn-danger {
  color: #fff;
  border-radius: 5rem;
  min-width: 8rem;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #e38218;
  border-color: #e38218;
}

.ant-btn-ghost {
  border-radius: 5rem;
  min-width: 8rem;
}

.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #e38218;
  background: transparent;
  border-color: #e38218;
}

.ant-modal-content {
  padding: 2rem;
  border-radius: 2rem;
}

.ant-card {
  border-radius: 2rem;
  padding: 1rem;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: #e38218;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e38218;
  border-color: #e38218;
}

.ant-tag {
  font-size: 14px;
}

.ant-tag:hover {
  color: #e38218;
  background: transparent;
  border-color: #e38218;
}

.center {
  justify-content: center;
  text-align: center;
}

.text-primary {
  color: #002766 !important;
}
